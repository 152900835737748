import { clocksNow, combine, elapsed, generateUUID, toServerDuration } from '@datadog/browser-core';
export function startVitalCollection(lifeCycle, pageStateHistory) {
    function isValid(vital) {
        return !pageStateHistory.wasInPageStateDuringPeriod("frozen" /* PageState.FROZEN */, vital.startClocks.relative, vital.duration);
    }
    function addDurationVital(vital) {
        if (isValid(vital)) {
            lifeCycle.notify(12 /* LifeCycleEventType.RAW_RUM_EVENT_COLLECTED */, processVital(vital, true));
        }
    }
    return {
        addDurationVital: addDurationVital,
        startDurationVital: function (startVital) {
            return createVitalInstance(function (vital) {
                addDurationVital(vital);
            }, startVital);
        },
    };
}
export function createVitalInstance(stopCallback, vitalStart) {
    var startClocks = clocksNow();
    var stopClocks;
    return {
        stop: function (vitalStop) {
            if (stopClocks) {
                return;
            }
            stopClocks = clocksNow();
            stopCallback(buildDurationVital(vitalStart, startClocks, vitalStop, stopClocks));
        },
    };
}
function buildDurationVital(vitalStart, startClocks, vitalStop, stopClocks) {
    var _a;
    if (vitalStop === void 0) { vitalStop = {}; }
    return {
        name: vitalStart.name,
        type: "duration" /* VitalType.DURATION */,
        startClocks: startClocks,
        duration: elapsed(startClocks.timeStamp, stopClocks.timeStamp),
        context: combine(vitalStart.context, vitalStop.context),
        details: (_a = vitalStop.details) !== null && _a !== void 0 ? _a : vitalStart.details,
    };
}
function processVital(vital, valueComputedBySdk) {
    var rawRumEvent = {
        date: vital.startClocks.timeStamp,
        vital: {
            id: generateUUID(),
            type: vital.type,
            name: vital.name,
            duration: toServerDuration(vital.duration),
            details: vital.details,
        },
        type: "vital" /* RumEventType.VITAL */,
    };
    if (valueComputedBySdk) {
        rawRumEvent._dd = {
            vital: {
                computed_value: true,
            },
        };
    }
    return {
        rawRumEvent: rawRumEvent,
        startTime: vital.startClocks.relative,
        customerContext: vital.context,
        domainContext: {},
    };
}
