import { Observable } from '../tools/observable';
import { objectValues, includes } from '../tools/utils/polyfills';
import { addEventListeners, addEventListener, DOM_EVENT } from './addEventListener';
export var PageExitReason = {
    HIDDEN: 'visibility_hidden',
    UNLOADING: 'before_unload',
    PAGEHIDE: 'page_hide',
    FROZEN: 'page_frozen',
};
export function createPageExitObservable(configuration) {
    return new Observable(function (observable) {
        var stopListeners = addEventListeners(configuration, window, [DOM_EVENT.VISIBILITY_CHANGE, DOM_EVENT.FREEZE], function (event) {
            if (event.type === DOM_EVENT.VISIBILITY_CHANGE && document.visibilityState === 'hidden') {
                /**
                 * Only event that guarantee to fire on mobile devices when the page transitions to background state
                 * (e.g. when user switches to a different application, goes to homescreen, etc), or is being unloaded.
                 */
                observable.notify({ reason: PageExitReason.HIDDEN });
            }
            else if (event.type === DOM_EVENT.FREEZE) {
                /**
                 * After transitioning in background a tab can be freezed to preserve resources. (cf: https://developer.chrome.com/blog/page-lifecycle-api)
                 * Allow to collect events happening between hidden and frozen state.
                 */
                observable.notify({ reason: PageExitReason.FROZEN });
            }
        }, { capture: true }).stop;
        var stopBeforeUnloadListener = addEventListener(configuration, window, DOM_EVENT.BEFORE_UNLOAD, function () {
            observable.notify({ reason: PageExitReason.UNLOADING });
        }).stop;
        return function () {
            stopListeners();
            stopBeforeUnloadListener();
        };
    });
}
export function isPageExitReason(reason) {
    return includes(objectValues(PageExitReason), reason);
}
