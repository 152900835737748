import { assign, runOnReadyState } from '@datadog/browser-core';
import { supportPerformanceTimingEvent, RumPerformanceEntryType } from '../../browser/performanceObservable';
import { getDocumentTraceId } from '../tracing/getDocumentTraceId';
import { FAKE_INITIAL_DOCUMENT, computeRelativePerformanceTiming } from './resourceUtils';
export function retrieveInitialDocumentResourceTiming(configuration, callback) {
    runOnReadyState(configuration, 'interactive', function () {
        var timing;
        var forcedAttributes = {
            entryType: RumPerformanceEntryType.RESOURCE,
            initiatorType: FAKE_INITIAL_DOCUMENT,
            traceId: getDocumentTraceId(document),
            toJSON: function () { return assign({}, timing, { toJSON: undefined }); },
        };
        if (supportPerformanceTimingEvent(RumPerformanceEntryType.NAVIGATION) &&
            performance.getEntriesByType(RumPerformanceEntryType.NAVIGATION).length > 0) {
            var navigationEntry = performance.getEntriesByType(RumPerformanceEntryType.NAVIGATION)[0];
            timing = assign(navigationEntry.toJSON(), forcedAttributes);
        }
        else {
            var relativePerformanceTiming = computeRelativePerformanceTiming();
            timing = assign(relativePerformanceTiming, {
                decodedBodySize: 0,
                encodedBodySize: 0,
                transferSize: 0,
                renderBlockingStatus: 'non-blocking',
                duration: relativePerformanceTiming.responseEnd,
                name: window.location.href,
                startTime: 0,
            }, forcedAttributes);
        }
        callback(timing);
    });
}
