import { dateNow, assign, addEventListeners, DOM_EVENT, monitor, setTimeout, relativeNow, runOnReadyState, addEventListener, objectHasValue, } from '@datadog/browser-core';
import { computeRelativePerformanceTiming } from '../domain/resource/resourceUtils';
import { RumPerformanceEntryType, supportPerformanceTimingEvent } from './performanceObservable';
function supportPerformanceObject() {
    return window.performance !== undefined && 'getEntries' in performance;
}
export function startPerformanceCollection(lifeCycle, configuration) {
    var cleanupTasks = [];
    if (supportPerformanceObject()) {
        var performanceEntries_1 = performance.getEntries();
        // Because the performance entry list can be quite large
        // delay the computation to prevent the SDK from blocking the main thread on init
        setTimeout(function () { return handleRumPerformanceEntries(lifeCycle, performanceEntries_1); });
    }
    if (window.PerformanceObserver) {
        var handlePerformanceEntryList_1 = monitor(function (entries) {
            return handleRumPerformanceEntries(lifeCycle, entries.getEntries());
        });
        var mainEntries = [
            RumPerformanceEntryType.NAVIGATION,
            RumPerformanceEntryType.LONG_TASK,
            RumPerformanceEntryType.PAINT,
        ];
        var experimentalEntries = [
            RumPerformanceEntryType.LARGEST_CONTENTFUL_PAINT,
            RumPerformanceEntryType.FIRST_INPUT,
            RumPerformanceEntryType.LAYOUT_SHIFT,
            RumPerformanceEntryType.EVENT,
        ];
        try {
            // Experimental entries are not retrieved by performance.getEntries()
            // use a single PerformanceObserver with buffered flag by type
            // to get values that could happen before SDK init
            experimentalEntries.forEach(function (type) {
                var observer = new window.PerformanceObserver(handlePerformanceEntryList_1);
                observer.observe({
                    type: type,
                    buffered: true,
                    // durationThreshold only impact PerformanceEventTiming entries used for INP computation which requires a threshold at 40 (default is 104ms)
                    // cf: https://github.com/GoogleChrome/web-vitals/blob/3806160ffbc93c3c4abf210a167b81228172b31c/src/onINP.ts#L209
                    durationThreshold: 40,
                });
                cleanupTasks.push(function () { return observer.disconnect(); });
            });
        }
        catch (e) {
            // Some old browser versions (ex: chrome 67) don't support the PerformanceObserver type and buffered options
            // In these cases, fallback to PerformanceObserver with entryTypes
            mainEntries.push.apply(mainEntries, experimentalEntries);
        }
        var mainObserver_1 = new PerformanceObserver(handlePerformanceEntryList_1);
        mainObserver_1.observe({ entryTypes: mainEntries });
        cleanupTasks.push(function () { return mainObserver_1.disconnect(); });
        if (supportPerformanceObject() && 'addEventListener' in performance) {
            // https://bugzilla.mozilla.org/show_bug.cgi?id=1559377
            var removePerformanceListener = addEventListener(configuration, performance, 'resourcetimingbufferfull', function () {
                performance.clearResourceTimings();
            }).stop;
            cleanupTasks.push(removePerformanceListener);
        }
    }
    if (!supportPerformanceTimingEvent(RumPerformanceEntryType.NAVIGATION)) {
        retrieveNavigationTiming(configuration, function (timing) {
            handleRumPerformanceEntries(lifeCycle, [timing]);
        });
    }
    if (!supportPerformanceTimingEvent(RumPerformanceEntryType.FIRST_INPUT)) {
        var stopFirstInputTiming = retrieveFirstInputTiming(configuration, function (timing) {
            handleRumPerformanceEntries(lifeCycle, [timing]);
        }).stop;
        cleanupTasks.push(stopFirstInputTiming);
    }
    return {
        stop: function () {
            cleanupTasks.forEach(function (task) { return task(); });
        },
    };
}
function retrieveNavigationTiming(configuration, callback) {
    function sendFakeTiming() {
        callback(assign(computeRelativePerformanceTiming(), {
            entryType: RumPerformanceEntryType.NAVIGATION,
        }));
    }
    runOnReadyState(configuration, 'complete', function () {
        // Send it a bit after the actual load event, so the "loadEventEnd" timing is accurate
        setTimeout(sendFakeTiming);
    });
}
/**
 * first-input timing entry polyfill based on
 * https://github.com/GoogleChrome/web-vitals/blob/master/src/lib/polyfills/firstInputPolyfill.ts
 */
function retrieveFirstInputTiming(configuration, callback) {
    var startTimeStamp = dateNow();
    var timingSent = false;
    var removeEventListeners = addEventListeners(configuration, window, [DOM_EVENT.CLICK, DOM_EVENT.MOUSE_DOWN, DOM_EVENT.KEY_DOWN, DOM_EVENT.TOUCH_START, DOM_EVENT.POINTER_DOWN], function (evt) {
        // Only count cancelable events, which should trigger behavior important to the user.
        if (!evt.cancelable) {
            return;
        }
        // This timing will be used to compute the "first Input delay", which is the delta between
        // when the system received the event (e.g. evt.timeStamp) and when it could run the callback
        // (e.g. performance.now()).
        var timing = {
            entryType: RumPerformanceEntryType.FIRST_INPUT,
            processingStart: relativeNow(),
            processingEnd: relativeNow(),
            startTime: evt.timeStamp,
            duration: 0, // arbitrary value to avoid nullable duration and simplify INP logic
            name: '',
        };
        if (evt.type === DOM_EVENT.POINTER_DOWN) {
            sendTimingIfPointerIsNotCancelled(configuration, timing);
        }
        else {
            sendTiming(timing);
        }
    }, { passive: true, capture: true }).stop;
    return { stop: removeEventListeners };
    /**
     * Pointer events are a special case, because they can trigger main or compositor thread behavior.
     * We differentiate these cases based on whether or not we see a pointercancel event, which are
     * fired when we scroll. If we're scrolling we don't need to report input delay since FID excludes
     * scrolling and pinch/zooming.
     */
    function sendTimingIfPointerIsNotCancelled(configuration, timing) {
        addEventListeners(configuration, window, [DOM_EVENT.POINTER_UP, DOM_EVENT.POINTER_CANCEL], function (event) {
            if (event.type === DOM_EVENT.POINTER_UP) {
                sendTiming(timing);
            }
        }, { once: true });
    }
    function sendTiming(timing) {
        if (!timingSent) {
            timingSent = true;
            removeEventListeners();
            // In some cases the recorded delay is clearly wrong, e.g. it's negative or it's larger than
            // the time between now and when the page was loaded.
            // - https://github.com/GoogleChromeLabs/first-input-delay/issues/4
            // - https://github.com/GoogleChromeLabs/first-input-delay/issues/6
            // - https://github.com/GoogleChromeLabs/first-input-delay/issues/7
            var delay = timing.processingStart - timing.startTime;
            if (delay >= 0 && delay < dateNow() - startTimeStamp) {
                callback(timing);
            }
        }
    }
}
function handleRumPerformanceEntries(lifeCycle, entries) {
    var rumPerformanceEntries = entries.filter(function (entry) {
        return objectHasValue(RumPerformanceEntryType, entry.entryType);
    });
    var rumAllowedPerformanceEntries = rumPerformanceEntries.filter(function (entry) { return !isIncompleteNavigation(entry); });
    if (rumAllowedPerformanceEntries.length) {
        lifeCycle.notify(0 /* LifeCycleEventType.PERFORMANCE_ENTRIES_COLLECTED */, rumAllowedPerformanceEntries);
    }
}
function isIncompleteNavigation(entry) {
    return entry.entryType === RumPerformanceEntryType.NAVIGATION && entry.loadEventEnd <= 0;
}
