import { Observable } from '../../tools/observable';
import { ValueHistory } from '../../tools/valueHistory';
import { relativeNow, clocksOrigin, ONE_MINUTE } from '../../tools/utils/timeUtils';
import { DOM_EVENT, addEventListener, addEventListeners } from '../../browser/addEventListener';
import { clearInterval, setInterval } from '../../tools/timer';
import { SESSION_TIME_OUT_DELAY } from './sessionConstants';
import { startSessionStore } from './sessionStore';
export var VISIBILITY_CHECK_DELAY = ONE_MINUTE;
var SESSION_CONTEXT_TIMEOUT_DELAY = SESSION_TIME_OUT_DELAY;
var stopCallbacks = [];
export function startSessionManager(configuration, productKey, computeSessionState, trackingConsentState) {
    var renewObservable = new Observable();
    var expireObservable = new Observable();
    // TODO - Improve configuration type and remove assertion
    var sessionStore = startSessionStore(configuration.sessionStoreStrategyType, productKey, computeSessionState);
    stopCallbacks.push(function () { return sessionStore.stop(); });
    var sessionContextHistory = new ValueHistory(SESSION_CONTEXT_TIMEOUT_DELAY);
    stopCallbacks.push(function () { return sessionContextHistory.stop(); });
    sessionStore.renewObservable.subscribe(function () {
        sessionContextHistory.add(buildSessionContext(), relativeNow());
        renewObservable.notify();
    });
    sessionStore.expireObservable.subscribe(function () {
        expireObservable.notify();
        sessionContextHistory.closeActive(relativeNow());
    });
    // We expand/renew session unconditionally as tracking consent is always granted when the session
    // manager is started.
    sessionStore.expandOrRenewSession();
    sessionContextHistory.add(buildSessionContext(), clocksOrigin().relative);
    trackingConsentState.observable.subscribe(function () {
        if (trackingConsentState.isGranted()) {
            sessionStore.expandOrRenewSession();
        }
        else {
            sessionStore.expire();
        }
    });
    trackActivity(configuration, function () {
        if (trackingConsentState.isGranted()) {
            sessionStore.expandOrRenewSession();
        }
    });
    trackVisibility(configuration, function () { return sessionStore.expandSession(); });
    trackResume(configuration, function () { return sessionStore.restartSession(); });
    function buildSessionContext() {
        return {
            id: sessionStore.getSession().id,
            trackingType: sessionStore.getSession()[productKey],
            isReplayForced: !!sessionStore.getSession().forcedReplay,
        };
    }
    return {
        findSession: function (startTime, options) { return sessionContextHistory.find(startTime, options); },
        renewObservable: renewObservable,
        expireObservable: expireObservable,
        sessionStateUpdateObservable: sessionStore.sessionStateUpdateObservable,
        expire: sessionStore.expire,
        updateSessionState: sessionStore.updateSessionState,
    };
}
export function stopSessionManager() {
    stopCallbacks.forEach(function (e) { return e(); });
    stopCallbacks = [];
}
function trackActivity(configuration, expandOrRenewSession) {
    var stop = addEventListeners(configuration, window, [DOM_EVENT.CLICK, DOM_EVENT.TOUCH_START, DOM_EVENT.KEY_DOWN, DOM_EVENT.SCROLL], expandOrRenewSession, { capture: true, passive: true }).stop;
    stopCallbacks.push(stop);
}
function trackVisibility(configuration, expandSession) {
    var expandSessionWhenVisible = function () {
        if (document.visibilityState === 'visible') {
            expandSession();
        }
    };
    var stop = addEventListener(configuration, document, DOM_EVENT.VISIBILITY_CHANGE, expandSessionWhenVisible).stop;
    stopCallbacks.push(stop);
    var visibilityCheckInterval = setInterval(expandSessionWhenVisible, VISIBILITY_CHECK_DELAY);
    stopCallbacks.push(function () {
        clearInterval(visibilityCheckInterval);
    });
}
function trackResume(configuration, cb) {
    var stop = addEventListener(configuration, window, DOM_EVENT.RESUME, cb, { capture: true }).stop;
    stopCallbacks.push(stop);
}
