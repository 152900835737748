import { addEventListener, Observable, setTimeout, clearTimeout, monitor, includes } from '@datadog/browser-core';
import { isAllowedRequestUrl } from '../domain/resource/resourceUtils';
// We want to use a real enum (i.e. not a const enum) here, to be able to check whether an arbitrary
// string is an expected performance entry
// eslint-disable-next-line no-restricted-syntax
export var RumPerformanceEntryType;
(function (RumPerformanceEntryType) {
    RumPerformanceEntryType["EVENT"] = "event";
    RumPerformanceEntryType["FIRST_INPUT"] = "first-input";
    RumPerformanceEntryType["LARGEST_CONTENTFUL_PAINT"] = "largest-contentful-paint";
    RumPerformanceEntryType["LAYOUT_SHIFT"] = "layout-shift";
    RumPerformanceEntryType["LONG_TASK"] = "longtask";
    RumPerformanceEntryType["NAVIGATION"] = "navigation";
    RumPerformanceEntryType["PAINT"] = "paint";
    RumPerformanceEntryType["RESOURCE"] = "resource";
})(RumPerformanceEntryType || (RumPerformanceEntryType = {}));
export function createPerformanceObservable(configuration, options) {
    return new Observable(function (observable) {
        var observer;
        var timeoutId;
        if (window.PerformanceObserver) {
            var isObserverInitializing_1 = true;
            var handlePerformanceEntries_1 = function (entries) {
                var rumPerformanceEntries = filterRumPerformanceEntries(configuration, entries);
                if (rumPerformanceEntries.length > 0) {
                    observable.notify(rumPerformanceEntries);
                }
            };
            observer = new PerformanceObserver(monitor(function (entries) {
                // In Safari the performance observer callback is synchronous.
                // Because the buffered performance entry list can be quite large we delay the computation to prevent the SDK from blocking the main thread on init
                if (isObserverInitializing_1) {
                    timeoutId = setTimeout(function () { return handlePerformanceEntries_1(entries.getEntries()); });
                }
                else {
                    handlePerformanceEntries_1(entries.getEntries());
                }
            }));
            try {
                observer.observe(options);
            }
            catch (_a) {
                // Some old browser versions (<= chrome 74 ) don't support the PerformanceObserver type and buffered options
                // In these cases, fallback to getEntriesByType and PerformanceObserver with entryTypes
                // TODO: remove this fallback in the next major version
                var fallbackSupportedEntryTypes = [
                    RumPerformanceEntryType.RESOURCE,
                    RumPerformanceEntryType.NAVIGATION,
                    RumPerformanceEntryType.LONG_TASK,
                    RumPerformanceEntryType.PAINT,
                ];
                if (includes(fallbackSupportedEntryTypes, options.type)) {
                    if (options.buffered) {
                        timeoutId = setTimeout(function () { return handlePerformanceEntries_1(performance.getEntriesByType(options.type)); });
                    }
                    observer.observe({ entryTypes: [options.type] });
                }
            }
            isObserverInitializing_1 = false;
        }
        manageResourceTimingBufferFull(configuration);
        return function () {
            observer === null || observer === void 0 ? void 0 : observer.disconnect();
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    });
}
var resourceTimingBufferFullListener;
function manageResourceTimingBufferFull(configuration) {
    if (!resourceTimingBufferFullListener && supportPerformanceObject() && 'addEventListener' in performance) {
        // https://bugzilla.mozilla.org/show_bug.cgi?id=1559377
        resourceTimingBufferFullListener = addEventListener(configuration, performance, 'resourcetimingbufferfull', function () {
            performance.clearResourceTimings();
        });
    }
    return function () {
        resourceTimingBufferFullListener === null || resourceTimingBufferFullListener === void 0 ? void 0 : resourceTimingBufferFullListener.stop();
    };
}
function supportPerformanceObject() {
    return window.performance !== undefined && 'getEntries' in performance;
}
export function supportPerformanceTimingEvent(entryType) {
    return (window.PerformanceObserver &&
        PerformanceObserver.supportedEntryTypes !== undefined &&
        PerformanceObserver.supportedEntryTypes.includes(entryType));
}
function filterRumPerformanceEntries(configuration, entries) {
    return entries.filter(function (entry) { return isAllowedResource(configuration, entry); });
}
function isAllowedResource(configuration, entry) {
    return entry.entryType === RumPerformanceEntryType.RESOURCE && isAllowedRequestUrl(configuration, entry.name);
}
