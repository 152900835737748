import { instrumentMethod, matchList, monitor, Observable, timeStampNow, setTimeout, clearTimeout, } from '@datadog/browser-core';
import { createPerformanceObservable, RumPerformanceEntryType } from '../browser/performanceObservable';
// Delay to wait for a page activity to validate the tracking process
export var PAGE_ACTIVITY_VALIDATION_DELAY = 100;
// Delay to wait after a page activity to end the tracking process
export var PAGE_ACTIVITY_END_DELAY = 100;
/**
 * Wait for the page activity end
 *
 * Detection lifecycle:
 * ```
 *                        Wait page activity end
 *              .-------------------'--------------------.
 *              v                                        v
 *     [Wait for a page activity ]          [Wait for a maximum duration]
 *     [timeout: VALIDATION_DELAY]          [  timeout: maxDuration     ]
 *          /                  \                           |
 *         v                    v                          |
 *  [No page activity]   [Page activity]                   |
 *         |                   |,----------------------.   |
 *         v                   v                       |   |
 *     (Discard)     [Wait for a page activity]        |   |
 *                   [   timeout: END_DELAY   ]        |   |
 *                       /                \            |   |
 *                      v                  v           |   |
 *             [No page activity]    [Page activity]   |   |
 *                      |                 |            |   |
 *                      |                 '------------'   |
 *                      '-----------. ,--------------------'
 *                                   v
 *                                 (End)
 * ```
 *
 * Note: by assuming that maxDuration is greater than VALIDATION_DELAY, we are sure that if the
 * process is still alive after maxDuration, it has been validated.
 */
export function waitPageActivityEnd(lifeCycle, domMutationObservable, configuration, pageActivityEndCallback, maxDuration) {
    var pageActivityObservable = createPageActivityObservable(lifeCycle, domMutationObservable, configuration);
    return doWaitPageActivityEnd(pageActivityObservable, pageActivityEndCallback, maxDuration);
}
export function doWaitPageActivityEnd(pageActivityObservable, pageActivityEndCallback, maxDuration) {
    var pageActivityEndTimeoutId;
    var hasCompleted = false;
    var validationTimeoutId = setTimeout(monitor(function () { return complete({ hadActivity: false }); }), PAGE_ACTIVITY_VALIDATION_DELAY);
    var maxDurationTimeoutId = maxDuration !== undefined
        ? setTimeout(monitor(function () { return complete({ hadActivity: true, end: timeStampNow() }); }), maxDuration)
        : undefined;
    var pageActivitySubscription = pageActivityObservable.subscribe(function (_a) {
        var isBusy = _a.isBusy;
        clearTimeout(validationTimeoutId);
        clearTimeout(pageActivityEndTimeoutId);
        var lastChangeTime = timeStampNow();
        if (!isBusy) {
            pageActivityEndTimeoutId = setTimeout(monitor(function () { return complete({ hadActivity: true, end: lastChangeTime }); }), PAGE_ACTIVITY_END_DELAY);
        }
    });
    var stop = function () {
        hasCompleted = true;
        clearTimeout(validationTimeoutId);
        clearTimeout(pageActivityEndTimeoutId);
        clearTimeout(maxDurationTimeoutId);
        pageActivitySubscription.unsubscribe();
    };
    function complete(event) {
        if (hasCompleted) {
            return;
        }
        stop();
        pageActivityEndCallback(event);
    }
    return { stop: stop };
}
export function createPageActivityObservable(lifeCycle, domMutationObservable, configuration) {
    return new Observable(function (observable) {
        var subscriptions = [];
        var firstRequestIndex;
        var pendingRequestsCount = 0;
        subscriptions.push(domMutationObservable.subscribe(notifyPageActivity), createPerformanceObservable(configuration, { type: RumPerformanceEntryType.RESOURCE }).subscribe(function (entries) {
            if (entries.some(function (entry) { return !isExcludedUrl(configuration, entry.name); })) {
                notifyPageActivity();
            }
        }), lifeCycle.subscribe(7 /* LifeCycleEventType.REQUEST_STARTED */, function (startEvent) {
            if (isExcludedUrl(configuration, startEvent.url)) {
                return;
            }
            if (firstRequestIndex === undefined) {
                firstRequestIndex = startEvent.requestIndex;
            }
            pendingRequestsCount += 1;
            notifyPageActivity();
        }), lifeCycle.subscribe(8 /* LifeCycleEventType.REQUEST_COMPLETED */, function (request) {
            if (isExcludedUrl(configuration, request.url) ||
                firstRequestIndex === undefined ||
                // If the request started before the tracking start, ignore it
                request.requestIndex < firstRequestIndex) {
                return;
            }
            pendingRequestsCount -= 1;
            notifyPageActivity();
        }));
        var stopTrackingWindowOpen = trackWindowOpen(notifyPageActivity).stop;
        return function () {
            stopTrackingWindowOpen();
            subscriptions.forEach(function (s) { return s.unsubscribe(); });
        };
        function notifyPageActivity() {
            observable.notify({ isBusy: pendingRequestsCount > 0 });
        }
    });
}
function isExcludedUrl(configuration, requestUrl) {
    return matchList(configuration.excludedActivityUrls, requestUrl);
}
function trackWindowOpen(callback) {
    return instrumentMethod(window, 'open', callback);
}
