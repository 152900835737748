import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/shared/interfaces/user';
import { ZoomMeeting } from '@app/shared/interfaces/zoom';
import { ProjectInfrastructureService } from '@app/shared/services/project-infrastructure.service';
import { UserService } from '@app/shared/services/user.service';
import { AuthService } from '@auth0/auth0-angular';
import { DateTime } from 'luxon';
import { catchError, combineLatest, map, Observable, of, switchMap, take } from 'rxjs';

@Component({
  selector: 'lfx-join-meeting',
  templateUrl: './join-meeting.component.html',
  styleUrls: ['./join-meeting.component.scss']
})
export class JoinMeetingComponent implements OnInit {
  public meeting$: Observable<ZoomMeeting | null>;
  public user$: Observable<User | null | undefined>;
  public password: string = '';

  public constructor(
    private activatedRoute: ActivatedRoute,
    private pisService: ProjectInfrastructureService,
    private authService: AuthService,
    private router: Router,
    private userService: UserService
  ) {}

  public ngOnInit(): void {
    this.meeting$ = combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams]).pipe(
      switchMap(([params, queryParams]) => {
        if (!queryParams.password) {
          this.router.navigate(['/password-required']);
          return of(null);
        }

        const query = new HttpParams({ fromObject: { password: queryParams.password } });
        this.password = queryParams.password;

        return this.pisService.getMeeting(params.id, query).pipe(
          take(1),
          catchError((error) => {
            if (error.status > 499) {
              this.router.navigate(['/service-unavailable']);
            } else if (error.status === 401) {
              this.router.navigate(['/not-authorized']);
            } else {
              this.router.navigate(['/not-found']);
            }

            return of(null);
          }),
          map((meeting) => {
            if (meeting) {
              // Check if meeting is a recurring meeting and if so, check if the first occurrence is in the past
              // If so, then we need to get the next occurrence
              if (meeting.occurrences && meeting.occurrences.length > 0) {
                const now = new Date();
                let firstOccurrence = meeting.occurrences[0];
                let meetingDate = DateTime.fromISO(firstOccurrence.start_time)
                  .plus({ minutes: meeting.duration + 40 })
                  .toJSDate();

                while (meetingDate < now) {
                  console.info('Meeting is in the past, getting next occurrence', meetingDate);
                  // Pop the first occurrence off the array
                  meeting.occurrences.shift();
                  firstOccurrence = meeting.occurrences[0];
                  meetingDate = DateTime.fromISO(firstOccurrence.start_time)
                    .plus({ minutes: meeting.duration + 40 })
                    .toJSDate();
                }
              }
            }

            return meeting;
          })
        );
      })
    );

    this.user$ = this.authService.isLoading$.pipe(
      switchMap((isLoading) => {
        if (isLoading) {
          return of(false);
        }

        return this.authService
          .getAccessTokenSilently({
            detailedResponse: true
          })
          .pipe(
            catchError((e) => {
              console.info('User is not authenticated - ', e.message);
              return of(false);
            })
          );
      }),
      switchMap((user) => {
        if (user) {
          const params = new HttpParams({
            fromObject: {
              basic: true
            }
          });
          return this.userService.getMe(params);
        }

        return of(null);
      })
    );
  }
}
