import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, shareReplay, tap } from 'rxjs';

import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public userID: string;
  public user: User;
  public user$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  private userServiceAPI = `${environment.apiV2URL}/user-services`;

  private urls = {
    users: `${this.userServiceAPI}/users`
  };

  private http: HttpClient = inject(HttpClient);

  public constructor() {}

  public getMe(params?: HttpParams): Observable<any> {
    return this.http.get<User>(`${this.urls.users}/me`, { params }).pipe(
      shareReplay(1),
      tap((user) => {
        this.user = user;
        this.user$.next(user);
        this.userID = user.ID;
      })
    );
  }
}
